var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap__content",class:{ 'wrap__content--preloader': !_vm.applications }},[(!_vm.applications)?_c('v-preloader',{attrs:{"message":_vm.errorMessage}}):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-lg-3"},[_c('v-sidebar',{attrs:{"active":['ReportingManagementSupervisor']}})],1),_c('div',{staticClass:"col-xl-10 col-lg-9"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"card__content"},[_vm._m(0),_c('div',{staticClass:"card__content--body"},[_c('div',{staticClass:"table__block--content"},[_c('div',{staticClass:"table__block--filter"},[_c('div',{staticClass:"table__filter--left"},[_c('div',{staticClass:"table__filter--search"},[_c('label',{staticClass:"search__label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.search),expression:"filters.search"}],attrs:{"type":"text","value":"","name":"filter_search","placeholder":_vm.$t('placeholder.search')},domProps:{"value":(_vm.filters.search)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filters, "search", $event.target.value)}}}),_c('button',[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35","stroke":"#52A5FC","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])])])])]),_c('div',{staticClass:"table__filter--right"})]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredlist,"loading":false,"options":_vm.options,"footer-props":{
                    'items-per-page-options': [5, 10, 20, 30, 40, 50],
                  }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('a',{staticClass:"tb__table--link"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.status",fn:function({ item }){return [_c('a',{staticClass:"tb__table--status",class:{
                        'tb__status--success': item.status == 'active',
                        'tb__status--error': item.status == 'deleted',
                      }},[_vm._v(" "+_vm._s(item.status == 'active' ? 'Активный' : '')+" "+_vm._s(item.status == 'deleted' ? 'Заблокирован' : '')+" "+_vm._s(item.status == 'new' ? 'Новый' : '')+" ")])]}},{key:"item.action",fn:function({ item }){return [_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({ content: _vm.$t('button.download'), class: ['tooltip__btn'] }),expression:"{ content: $t('button.download'), class: ['tooltip__btn'] }",modifiers:{"top-center":true}}],staticClass:"tb__table--btn tb__table--list",attrs:{"to":_vm.urlApi + '/api/v1/generate/report/' + item.id + '?type_report=2'}},[_c('svg',{attrs:{"width":"19","height":"19","viewBox":"0 0 19 19","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M16.382 11.927v3a1.5 1.5 0 01-1.5 1.5h-10.5a1.5 1.5 0 01-1.5-1.5v-3M5.882 8.177l3.75 3.75 3.75-3.75M9.632 11.927v-9","stroke":"#52A5FC","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])])]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error"},slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t('system_message.search'))+" ")])],1)],1)])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card__content-header"},[_c('div',{staticClass:"content__title"},[_c('div',{staticClass:"content__title--element"},[_c('div',{staticClass:"content__title--text"},[_vm._v(" Сведения об освоении квоты вылова рыбы и других водных животных пользователями ")])])])])
}]

export { render, staticRenderFns }